let baseUrl = null

class HttpParams {

    static buildServerUrl() {
        if (baseUrl == null) {
            this.baseUrl = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_VERSION
        }
    }

    static PARAM_CONTENT_TYPE = 'Content-Type'
    static JSON = 'application/json'
    static URL_ENCODED = 'application/x-www-form-urlencoded'

    // ACCOUNT
    static getLoginApi() {
        this.buildServerUrl()
        return this.baseUrl + '/user/login'
    }

    static getPwdRequestApiApi() {
        this.buildServerUrl()
        return this.baseUrl + '/user/request-reset-password'
    }

    static getWorkspaceNameApi() {
        this.buildServerUrl()
        return this.baseUrl + '/user/retrieve-work-space'
    }

    static getMemberRequestApi() {
        this.buildServerUrl()
        return this.baseUrl + '/organization/member_request'
    }

    static getVerifyMailResendApi() {
        this.buildServerUrl()
        return this.baseUrl + '/user/send_verify_mail'
    }

    static getRegistrationApi() {
        this.buildServerUrl()
        return this.baseUrl + '/user/registration'
    }
    static getNewVersionNumberApi() {
        this.buildServerUrl()
        return this.baseUrl + '/project/version'
    }
    static getCurrentVersionNumberApi() {
        this.buildServerUrl()
        return this.baseUrl + '/project/current-version'
    }

    static getResetPasswordApi() {
        this.buildServerUrl()
        return this.baseUrl + '/user/reset-password'
    }

    // ORGANIZATION
    static getAddOrganizationApi() {
        this.buildServerUrl()
        return this.baseUrl + '/organization/add'
    }

    static getEditOrganizationApi() {
        this.buildServerUrl()
        return this.baseUrl + '/organization/edit'
    }

    static getOrganizationListApi() {
        this.buildServerUrl()
        return this.baseUrl + '/organization/list'
    }

    static getWorkSpaceMembersListApi() {
        this.buildServerUrl()
        return this.baseUrl + '/organization/WorkSpaceMembers'
    }

    static getOrganizationAddMemberApi() {
        this.buildServerUrl()
        return this.baseUrl + '/organization/add-member'
    }

    static getOrganizationUpdateMemberApi() {
        this.buildServerUrl()
        return this.baseUrl + '/organization/update-member'
    }

    static getOrganizationDeleteMemberApi() {
        this.buildServerUrl()
        return this.baseUrl + '/organization/delete-member'
    }

    static getOrganizationMemberListApi() {
        this.buildServerUrl()
        return this.baseUrl + '/organization/members'
    }

    // APIs

    // PROJECT
    static getProjectTypeApi() {
        this.buildServerUrl()
        return this.baseUrl + '/project/types'
    }

    static getProjectListApi() {
        this.buildServerUrl()
        return this.baseUrl + '/project/list'
    }
    static getAllProjectListApi() {
        this.buildServerUrl()
        return this.baseUrl + '/project/get-all'
    }

    static getProjectAddApi() {
        this.buildServerUrl()
        return this.baseUrl + '/project/add'
    }

    static getProjectEditApi() {
        this.buildServerUrl()
        return this.baseUrl + '/project/edit'
    }

    static getProjectAddMemberApi() {
        this.buildServerUrl()
        return this.baseUrl + '/project/add-member'
    }

    static getProjectAddBulkMembersApi() {
        this.buildServerUrl()
        return this.baseUrl + '/project/add-members'
    }

    static getProjectDeleteMemberApi() {
        this.buildServerUrl()
        return this.baseUrl + '/project/delete-member'
    }

    static getProjectDeleteApi() {
        this.buildServerUrl()
        return this.baseUrl + '/project/delete-project'
    }
    static getDepartmentDeleteApi() {
        this.buildServerUrl()
        return this.baseUrl + '/organization/delete-department'
    }

    static getProjectMemberListApi() {
        this.buildServerUrl()
        return this.baseUrl + '/project/members'
    }

    // TASK
    static getTaskAddApi() {
        this.buildServerUrl()
        return this.baseUrl + '/task/add'
    }

    static getTaskEditApi() {
        this.buildServerUrl()
        return this.baseUrl + '/task/edit'
    }

    static getTaskListApi() {
        this.buildServerUrl()
        return this.baseUrl + '/task/list'
    }

    static getTaskUpdateApi() {
        this.buildServerUrl()
        return this.baseUrl + '/task/update'
    }

    // GLOBAL - ROLE
    static getGlobalRoleApi() {
        this.buildServerUrl()
        return this.baseUrl + '/global/role'
    }

    // GET TOKEN
    static getTokenApi() {
        this.buildServerUrl()
        return this.baseUrl + '/user/get-access-tokens'
    }

    // DASHBOARD
    static getSummaryApi() {
        this.buildServerUrl()
        return this.baseUrl + '/dashboard/summary'
    }

    static getRecentApi() {
        this.buildServerUrl()
        return this.baseUrl + '/dashboard/recent'
    }

    static getActivityChartApi() {
        this.buildServerUrl()
        return this.baseUrl + '/dashboard/activity-chart'
    }

    static getDashboardProjectsApi() {
        this.buildServerUrl()
        return this.baseUrl + '/dashboard/projects'
    }

    static getDashboardAppsApi() {
        this.buildServerUrl()
        return this.baseUrl + '/dashboard/apps'
    }
    static getDashboardAppsUserApi() {
        this.buildServerUrl()
        return this.baseUrl + '/dashboard/apps-users'
    }

    // ACTIVITY
    static getSignUrlApi() {
        this.buildServerUrl()
        return this.baseUrl + '/activity/sign-url'
    }

    static getDashboardWindowLogApi() {
        this.buildServerUrl()
        return this.baseUrl + '/dashboard/window-log'
    }

    static getReportPreparationData() {
        this.buildServerUrl()
        return this.baseUrl + '/report/get-data'
    }

    static getDashboardActivityApi() {
        this.buildServerUrl()
        return this.baseUrl + '/dashboard/activity'
    }

    static getActivityDeleteFileApi() {
        this.buildServerUrl()
        return this.baseUrl + '/activity/delete-file'
    }

    static getActivityEditApi() {
        this.buildServerUrl()
        return this.baseUrl + '/dashboard/edit-data'
    }


    static getJiraInitApi() {
        this.buildServerUrl()
        return this.baseUrl + '/jira/connect'
    }

    static getJiraConfigApi() {
        this.buildServerUrl()
        return this.baseUrl + '/jira/config'
    }

    static getJiraConnectionCallbackApi() {
        this.buildServerUrl()
        return this.baseUrl + '/jira/connection-callback'
    }

    static getIntegrationVendors() {
        this.buildServerUrl()
        return this.baseUrl + '/jira/integration_vendors'
    }

    static getJiraSyncApi() {
        this.buildServerUrl()
        return this.baseUrl + '/jira/sync_jira'
    }

    static getWorkspaceApi() {
        this.buildServerUrl()
        return this.baseUrl + '/user/workspace'
    }

    static getProfileApi() {
        this.buildServerUrl()
        return this.baseUrl + '/user/me'
    }

    static getWorkspaceUpdateApi(id) {
        this.buildServerUrl()
        return this.baseUrl + '/user/workspace/' + id
    }

    static getWorkspace(origin) {
        this.buildServerUrl()
        return this.baseUrl + `/user/get-workspace?origin_url=${origin}`
    }


    // PARAMS

    // AUTH
    static PARAM_AUTHORIZATION = 'authorization'
    static BEARER = 'Bearer '

    // ORGANIZATION
    static PARAM_ORGANIZATION_ID = 'organization_id'
    static PARAM_PROJECT_TYPE_ID = 'project_type_id'

    // PARAMS
    static PARAM_EMAIL = 'email'
    static PARAM_NAME = 'name'
    static PARAM_IMAGE = 'image'
    static PARAM_AUTH_TYPE = 'auth_type'
    static PARAM_WORK_SPACE = 'work_space'
    static PARAM_PASSWORD = 'password'
    static PARAM_CLIENT_ID = 'client_id'
    static PARAM_ORIGIN_URL = 'origin_url'
    static PARAM_ACCESS_PERMISSION = 'access_permission'
    static PARAM_NOE = 'number_of_employee'
    static PARAM_ADDRESS = 'address'
    static PARAM_DOMAIN_LINK = 'domain'
    static PARAM_USER_ID = 'user_id'
    static PARAM_DESCRIPTION = 'description'
    static PARAM_PROJECT_ID = 'project_id'
    static PARAM_ORGANIZATION_ID = 'organization_id'
    static PARAM_PROJECT_MEMBER_ID = 'project_member_id'
    static PARAM_TASK_ID = 'task_id'
    static PARAM_TASK_STATUS = 'task_status'
    static PARAM_ROLE_ID = 'role_id'
    static PARAM_ROLE_TYPE = 'type'
    static PARAM_TITLE = 'title'
    static PARAM_TASK_ORIGIN_URL = 'task_origin_url'

    static PARAM_FROM_DATE = 'from_date'
    static PARAM_TO_DATE = 'to_date'
    static PARAM_TIMEZONE = 'timezone'
    static PARAM_APPNAME = 'app_name'
    static PARAM_FILE_NAME = 'file_name'


    static PARAM_SCOPE = 'scope'
    static PARAM_SELECTED_USER_ID = 'selected_user_id'
    static PARAM_OLD_PASSWORD_HASH = 'old_password_hash'
    static PARAM_OLD_PASSWORD_PLAIN = 'old_password_plain'
    static PARAM_NEW_PASSWORD = 'new_password'
    static PARAM_TOKEN = 'token'

    static PARAM_ACTIVITY_ID = 'activity_id'
    static PARAM_COMMENT = 'comment'
    static PARAM_ACTIVITY_TYPE = 'activity_type'
    static PARAM_JIRA_HOST_URL = 'jira_host_url'
    static PARAM_JIRA_URL = 'jira_url'
    static PARAM_CONSUMER_KEY = 'consumer_key'
    static PARAM_PRIVATE_KEY = 'private_key'
    static PARAM_JIRA_API_VERSION = 'jira_api_version'
    static PARAM_JIRA_TOKEN = 'jira_token'
    static PARAM_JIRA_TOKEN_SECRET = 'jira_token_secret'
    static PARAM_JIRA_OAUTH_VERIFIER="jira_oauth_verifier"
    static PARAM_COMPANY_NAME = 'company_name'
    static PARAM_COMPANY_EMAIL = 'company_email'
    static PARAM_COMPANY_LOGO = 'company_logo'
    static PARAM_SUBSCRIPTION_ID = 'subscription_id'
    static PARAM_PROJECT_STATUS = 'project_status'

}

new HttpParams()

module.exports = HttpParams
